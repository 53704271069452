import React, { useState } from "react";
import logo from "../Assets/palms_logo.svg";
import hamburger from "../Assets/menu.svg";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import { motion } from "framer-motion";

const Header = () => {
  const [showToggle, setShowToggle] = useState(true);
  const handleToggle = () => {
    setShowToggle(!showToggle);
  };
  return (
    <section className="w-full">
      <div className="flex justify-between md:block">
        <div className="md:hidden">
          <img src={logo} alt="logo" />
        </div>
        <div className="hidden md:flex justify-between items-center">
          <div>
            <img src={logo} alt="logo" />
          </div>
          <div className="hidden md:block">
            <ul className="flex flex-row gap-8 text-white">
              <li className="pt-2 cursor-pointer">
                <Link to="aboutus" smooth={true} duration={700}>
                  About Us
                </Link>
              </li>
              <li className="pt-2 cursor-pointer">
                <Link to="contactus" smooth={true} duration={700}>
                  Contact Us
                </Link>
              </li>
              <li>
                <button className="bg-darkGreen py-3 px-3 rounded-lg">
                  Download App
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="shadow-lg">
          {showToggle ? (
            <img
              src={hamburger}
              alt=",,,"
              className="md:hidden cursor-pointer"
              onClick={handleToggle}
            />
          ) : (
            <FaTimes
              className="text-white text-2xl md:hidden cursor-pointer mt-2"
              onClick={handleToggle}
            />
          )}
        </div>
      </div>
      {!showToggle && (
        <motion.div
          whileInView={{ y: [20, 0], opacity: [0, 1] }}
          transition={{ duration: 1.0 }}
          key={1}
          className="md:hidden w-[60%] px-4 font-light mt-4 pt-4 py-8 bg-darkBlue rounded absolute right-0  "
        >
          <ul className="flex flex-col text-center gap-6 text-white">
            <li className="pt-2 cursor-pointer">
              <Link to="aboutus" smooth={true} duration={700}>
                About Us
              </Link>
            </li>
            <li className="pt-2 cursor-pointer ">
              <Link to="contactus" smooth={true} duration={700}>
                Contact Us
              </Link>
            </li>
            <li>
              <button className="bg-darkGreen py-3 px-3 rounded-lg">
                Dowbload App
              </button>
            </li>
          </ul>
        </motion.div>
      )}
    </section>
  );
};

export default Header;
