import React from "react";
import {motion} from "framer-motion"
import RightImageCard from "../Components/Cards/RightImageCard";
import icon1 from "../Components/Assets/Frame 3548.svg";
import icon2 from "../Components/Assets/Frame 3549 (1).svg";
import icon3 from "../Components/Assets/Frame 3550.svg";
import icon4 from "../Components/Assets/Frame 3553.svg";
import icon5 from "../Components/Assets/Frame 3554.svg";
import icon6 from "../Components/Assets/Frame 3549 (2).svg";

const Partners = () => {
  return (
    <>
      <section className="px-6 pb-12 lg:px-12">
        <div className="flex flex-col lg:justify-center lg:items-center">
          <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className="lg:text-center lg:w-[68.5rem]">
            <h1 className="font-bold text-xl lg:text-2xl">Our Values</h1>
            <p className="font-light pt-2">
              At PALMS Digital, we are committed to the success of micro and
              small businesses. Through our mission, vision, and values, we
              strive to be the trusted partner that enables their growth and
              drives economic prosperity in Nigeria, Africa, and the
              Afro-Caribbean region.
            </p>
          </motion.div>
        </div>

        <div className="pt-8 grid gap-8 items-center md:grid-cols-2 lg:grid-cols-3">
          <RightImageCard
            imgSrc={icon1}
            imgAlt="about image"
            title="Enpowerment"
            description="We believe in empowering micro and small businesses to unlock their full potential. We are dedicated to providing them with the financial resources and support they need to succeed."
          />
          <RightImageCard
            imgSrc={icon6}
            imgAlt="about image"
            title="Accessibility"
            description="We ensure that our lending solutions are accessible to all deserving businesses, regardless of their size or location. We strive to remove barriers and make the borrowing process simple, transparent, and inclusive."
          />
          <RightImageCard
            imgSrc={icon3}
            imgAlt="about image"
            title="Integrity"
            description="We uphold the highest standards of integrity and transparency in all our interactions. We build long-term relationships based on trust, honesty, and fairness."
          />
          <RightImageCard
            imgSrc={icon2}
            imgAlt="about image"
            title="Innovation"
            description="We embrace innovation and continuously seek new ways to improve our services and meet the evolving needs of micro and small businesses. We leverage technology to streamline processes, enhance efficiency, and deliver a seamless digital experience."
          />
          <RightImageCard
            imgSrc={icon4}
            imgAlt="about image"
            title="Customer-Centricity"
            description="Our customers are at the heart of everything we do. We listen to their needs, understand their challenges, and tailor our solutions to meet their unique requirements. We are dedicated to providing exceptional customer service and building lasting partnerships."
          />
          <RightImageCard
            imgSrc={icon5}
            imgAlt="Community Inpact"
            title="Enpowerment"
            description="We recognize our role in creating positive social and economic impact. We contribute to the growth and development of the communities we serve by supporting local businesses, promoting financial literacy, and fostering entrepreneurship."
          />
        </div>
      </section>
    </>
  );
};

export default Partners;
