import React from "react";
import { motion } from "framer-motion";

const Cards = ({imgSrc, imgAlt, title, description, className, description_className}) => {
  return (
    <section className="grid  px-6  py-24 leading-6 tracking-wide text-darkBlue lg:px-12 lg:grid-cols-2">
       <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className={className}>
        <img src={imgSrc} alt={imgAlt} />
      </motion.div>
      <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className={description_className}>
        <h1 className="font-bold text-xl lg:text-2xl">{title}</h1>
        <p className="font-light">{description}</p>
      </motion.div>
    </section>
  );
};

export default Cards;
