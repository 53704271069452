import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutUs from "./Pages/AboutUs";
import Home from "./Pages/Home";
import Mission from "./Pages/Mission";
import Partners from "./Pages/Partners";
import ContactUs from "./Pages/ContactUs";
import Footer from "./Pages/Footer";

function App() {
  return (
    <div>
      <ToastContainer
        position="top-center"
        className="w-full"
        style={{ width: "600px" }}
      />
      <Home />
      <AboutUs />
      <Mission />
      <Partners />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
