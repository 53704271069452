import React from "react";
import people from "../Components/Assets/Rectangle 6.png";
import { motion } from "framer-motion";


const Mission = () => {
  return (
    <section className="pb-24 w-full">
      <div className=" bg-gradient-to-tl from-darkBlue to-darkBlue h-[59.25ren]  leading-6 tracking-wide text-darkBlue  bg-center relative lg:h-[26rem]">
        <img
          src={people}
          alt="person"
          className=" w-full h-full object-cover absolute mix-blend-overlay"
        />
        <div className="text-white grid  px-8 py-8 lg:px-48 lg:grid-cols-3 ">
     <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1}>
            <h1 className="font-bold text-2xl ">Mission</h1>
          </motion.div>
          <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1}className="pt-3 md:pt-0 lg:col-span-2 ">
            <p className="font-light ">
              At PALMS Digital, our mission is to empower micro and small
              businesses in Nigeria, Africa, and the Afro-Caribbean region by
              providing flexible and accessible lending solutions. We are
              committed to fostering economic growth, creating opportunities,
              and supporting entrepreneurship within these vibrant communities
              within our target market. Our goal is to be their trusted
              financial partner that small businesses can rely on to fuel their
              growth and achieve their dreams.
            </p>
          </motion.div>
        </div>

        <div className="text-white grid px-6  py-8 lg:grid-cols-3 lg:px-48">
        <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1}>
            <h1 className="font-bold text-xl lg:text-2xl ">Vision</h1>
          </motion.div>
          <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className="pt-3 md:pt-0 lg:col-span-2 ">
            <p className="font-light ">
              At PALMS Digital, our mission is to empower micro and small
              businesses in Nigeria, Africa, and the Afro-Caribbean region by
              providing flexible and accessible lending solutions. We are
              committed to fostering economic growth, creating opportunities,
              and supporting entrepreneurship within these vibrant communities
              within our target market. Our goal is to be their trusted
              financial partner that small businesses can rely on to fuel their
              growth and achieve their dreams.
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
