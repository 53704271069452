import React from "react";
import happyMan from "../Components/Assets/happy-man-using-smartphone-cafe 1.svg";
import Header from "../Components/Header/Header";
import { motion } from "framer-motion";


const Home = () => {
  return (
    <section className="bg-darkBlue px-6 pt-6 pb-24 lg:px-12 w-full">
      <Header />
      <div className="text-white  pt-12 leading-4 tracking-wide flex flex-col-reverse justify-between lg:flex-row lg:leading-9 lg:tracking-wider">
        <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className="pt-6 lg:w-[620px] lg:pt-12">
          <h1 className="text-2xl lg:text-5xl font-black">
            Seamlessly <span className="text-darkGreen">Access loans</span> to
            boost your sales
          </h1>
          <p className="pt-8 text-xl font-extralight lg:text-2xl lg:font-light">
            PALMS Digital (Pan African Lending and Management Solution) is an
            SME focused digitalized and automated lending and value chain
            management platform.
          </p>
        </motion.div>
        <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} >
          <img src={happyMan} alt="happy_manage" />
        </motion.div>
      </div>
    </section>
  );
};

export default Home;
