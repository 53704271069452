import React from "react";
import { motion } from "framer-motion";
import logo from "../Components/Assets/palms_logo.svg";
import instagram from "../Components/Assets/instagram.svg";
import linkedin from "../Components/Assets/linkedin.svg";

const Footer = () => {
  return (
    <section
      id="contactus"
      className="grid  px-6  py-8 leading-6 tracking-wide text-darkBlue lg:px-12 lg:grid-cols-2 "
    >
      <motion.div
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0 }}
        key={1}
        className="lg:w-[530px] flex-col justify-center items-center text-center lg:text-start"
      >
        <div className="flex justify-center items-center text-center lg:justify-start ">
          <img src={logo} alt="" className="" />
        </div>
        <p className="font-light py-4">
          Need to get in touch with us? Fill out the contact form and our
          business team will be in touch.
        </p>
      </motion.div>

      <motion.div
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0 }}
        key={1}
        className="flex flex-col gap-4 mt-8 lg:mt-0 "
      >
        <div className="flex flex-row gap-4 justify-center items-center text-center  lg:justify-end">
          <img src={instagram} alt="" className=" cursor-pointer" />
          <img src={linkedin} alt="" className=" cursor-pointer" />
        </div>
        <a
          className="flex justify-center items-center text-center  lg:justify-end underline"
          href="mailto:info@palms.com"
        >
          info@palmsdigital.com.ng
        </a>
      </motion.div>
    </section>
  );
};

export default Footer;
