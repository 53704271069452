import React from "react";
import {motion} from "framer-motion"

const RightImageCard = ({ imgSrc, imgAlt, title, description, className }) => {
  return (
    <>
      <motion.div 
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0}}
        key={1} className="leading-6 tracking-wide text-darkBlue ">
        <div className={className}>
          <img src={imgSrc} alt={imgAlt} />
        </div>
        <div className="pt-3 flex flex-col gap-4 ">
          <h1 className="font-bold tet-xl lg:text-2xl">{title}</h1>
          <p className="font-light">{description}</p>
        </div>
      </motion.div>
    </>
  );
};

export default RightImageCard;
