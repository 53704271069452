import React, { useState } from "react";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import warning from "../Components/Assets/warning.svg";
import loaderIcon from "../Components/Assets/loaderIcon.svg";
import contactUsImage from "../Components/Assets/contactUsImage.svg";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    help: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    help: Yup.string().required("This field is required!"),
    email: Yup.string()
      .trim()
      .required("Email can not be empty")
      .email("This can’t be right. Email Invalid"),
    message: Yup.string()
      .required("message can not be empty")
      .min(10, "message must be at least 10 characters"),
  });
  const handleSubmit = async (values,{ resetForm }) => {
    try {
      setLoading(true);
      console.log("Form submitted successfully");
      setTimeout(() => {
        setLoading(false);
        toast.success("Form submitted successfully");
        resetForm();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section
      id="contactus"
      className="grid  px-6  py-24 leading-6 tracking-wide text-darkBlue lg:px-12 lg:grid-cols-2 bg-lightGreen"
    >
      <motion.div
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0 }}
        key={1}
        className="lg:w-[530px]"
      >
        <h1 className="font-bold text-xl lg:text-2xl">Contact Us</h1>
        <p className="font-light py-4">
          Need to get in touch with us? Fill out the contact form and our
          business team will be in touch.
        </p>
        <img src={contactUsImage} alt="" className="w-full" />
      </motion.div>

      <motion.div
        whileInView={{ y: [30, 0], opacity: [0, 1] }}
        transition={{ duration: 2.0 }}
        key={1}
        className="flex flex-col gap-4 mt-8 lg:mt-0"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialze={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="">
              <label className="text-[16px] text-start">
                Email Address
                <sup className="text-[#E01507] font-normal">*</sup>
              </label>
              <Field
                name="email"
                type="text"
                placeholder="Enter your emaill address"
            
                className={`${
                  errors.email && touched.email ? "border-red-600 lg:mb-2" : ""
                } p-2 mb-2   rounded border border-[#BAB6B3] text-black placeholder:text-[#BAB6B3] w-full outline-none }`}
              />
              {errors.email && touched.email && (
                <div className="flex items-center text-red-600 ml-2 text-sm mb-4 ">
                  <img src={warning} alt="" className="w-4" />
                  <ErrorMessage name="email" component="div" className="ml-2" />
                </div>
              )}

              <label className="text-[16px] text-start">
                How can we help you
                <sup className="text-[#E01507] font-normal">*</sup>
              </label>
              <Field
                name="help"
                type="text"
                placeholder="Message title"
                className={`${
                  errors.help && touched.help ? "border-red-600 lg:mb-2" : ""
                } p-2 mb-2  rounded border border-[#BAB6B3] text-black placeholder:text-[#BAB6B3] w-full outline-none }`}
              />
              {errors.help && touched.help && (
                <div className="flex items-center text-red-600 ml-2 text-sm mb-4 ">
                  <img src={warning} alt="" className="w-4 " />
                  <ErrorMessage name="help" component="div" className="ml-2" />
                </div>
              )}

              <label className="text-[16px] text-start">
                Your Message
                <sup className="text-[#E01507] font-normal">*</sup>
              </label>
              <Field
                name="message"
                component="textarea"
                rows="6"
                cols="55"
                type="text"
                placeholder="Enter your message"
                className={`${
                  errors.message && touched.message
                    ? "border-red-600 lg:mb-2"
                    : ""
                } p-2 mb-4   rounded border border-[#BAB6B3] text-black placeholder:text-[#BAB6B3] w-full outline-none}`}
              />
              {errors.message && touched.message && (
                <div className="flex items-center text-red-600 ml-2 text-sm mb-4 -mt-2">
                  <img src={warning} alt="" className="w-4" />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="ml-2"
                  />
                </div>
              )}
              <button
                type="submit"
                className="w-full flex flex-col items-center place-items-center font-semibold  py-2 mt-4 px-2  rounded-lg cursor-pointer    text-white  lg:py-3 lg:px-2 bg-[#39C695]"
              >
                {loading ? (
                  <img src={loaderIcon} alt="" className="h-6 w-6 " />
                ) : (
                  <h1 className="">Send Message</h1>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </motion.div>
    </section>
  );
};

export default ContactUs;
