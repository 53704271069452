import React from "react";
import people from "../Components/Assets/people.svg";
import LeftImageCard from "../Components/Cards/LeftImageCard";

const AboutUs = () => {
  return (
    <section id="aboutus">
      <LeftImageCard
        imgSrc={people}
        imgAlt="about image"
        className="lg:bg-lightGreen  "
        description_className="pt-12  flex flex-col gap-4 lg:bg-lightGreen lg:pr-20"
        title="About us"
        description="SMEs are important drivers of economic growth and development in Africa and have shown remarkable growth in recent years. However, more needs to be done to support their growth and sustainability, to provide targeted financial support, building digital infrastructure, and promoting regional and international trade. PALMS has a strategic collaboration between AFREXIM, MTN and GPI to utilize technology that will improve and manage the growth of capital for SMEs. "
      />
    </section>
  );
};

export default AboutUs;
